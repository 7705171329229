import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import { useSocket } from "../../context/SocketContext";
import { useSelector } from "react-redux";
import {
  getHighlightOrders,
  getAcceptScreenOrder,
  statusChange,
} from "../../API/order"; // Import the status change API
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function AudioCard({ audioSrc }) {
  const [cardsData, setCardsData] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const socket = useSocket();
  const user = useSelector((state) => state.user.userData);
  const storeLocation = user?.storeLocation ?? "STR_1";
  const navigate = useNavigate();

  const redirectBack = () => {
    navigate("/ongoing-orders");
  };

  const handleAcceptCard = async (cardIndex) => {
    if (selectedCard) {
      const payload = {
        orderCode: selectedCard.orderCode,
        orderStatus: "placed",
      };

      try {
        const response = await statusChange(payload);
        if (response.status === 200) {
          if (response.data.status === 200) {
            toast.success(
              response.data.message || "Order accepted successfully!"
            );
            const updatedCardsData = cardsData.filter(
              (_, index) => index !== cardIndex
            );
            setCardsData(updatedCardsData);
            if (updatedCardsData.length > 0) {
              const nextCardIndex = Math.min(
                cardIndex,
                updatedCardsData.length - 1
              );
              setSelectedCard(updatedCardsData[nextCardIndex]);
            } else {
              setSelectedCard(null);
              handleClose();
            }
          } else {
            toast.error(response.data.message);
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error);
      }
    }

    const updatedCardsData = cardsData.filter(
      (_, index) => index !== cardIndex
    );
    setCardsData(updatedCardsData);

    if (updatedCardsData.length > 0) {
      const nextCardIndex = Math.min(cardIndex, updatedCardsData.length - 1);
      setSelectedCard(updatedCardsData[nextCardIndex]);
    } else {
      setSelectedCard(null);
      handleClose();
    }
  };

  const handleSkipCard = () => {
    if (cardsData.length > 1) {
      const nextCardIndex = (selectedCardIndex + 1) % cardsData.length;
      setSelectedCardIndex(nextCardIndex);
      handleShow(cardsData[nextCardIndex]);
    }
  };

  const handleShow = (card) => {
    setSelectedCard(card);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedCard(null);
  };

  useEffect(() => {
    if (cardsData.length > 0) {
      setSelectedCard(cardsData[selectedCardIndex || 0]);
      handleShow(cardsData[selectedCardIndex || 0]);
    }
  }, [cardsData, selectedCardIndex]);

  const loadingExistingOrder = async () => {
    const result = await getAcceptScreenOrder(storeLocation);
    if (result.status === 200) {
      let data = result.data.data;
      data = data.filter((order) => order.status === "pending");
      if (data.length > 0) {
        setCardsData(data);
        console.log("Orders from apis", data);
      }
    }
  };

  useEffect(() => {
    loadingExistingOrder();
  }, []);

  useEffect(() => {
    socket.on("onlineorder", (data) => {
      if (data?.storeCode === storeLocation) {
        setCardsData((prevCardsData) => [...prevCardsData, data]);
      }
    });
    socket.on("orderstatuschange", (data) => {
      if (data?.storeCode === storeLocation) {
        console.log(data, cardsData);
        const restOrders = cardsData.filter(
          (card) => card.orderCode !== data.orderCode
        );
        console.log("Remiaining orders are", restOrders);
        setCardsData(restOrders);
      }
    });
    return () => {
      socket.off("onlineorder");
      socket.off("orderstatuschange");
    };
  }, [socket, cardsData, storeLocation]);

  useEffect(() => {
    if (cardsData.length > 0 && selectedCardIndex === null) {
      setSelectedCardIndex(0);
      setSelectedCard(cardsData[0]);
    }
  }, [cardsData]);

  return (
    <>
      {cardsData.length > 0 ? (
        <>
          <div className="container-fluid">
            <div className="container mt-4">
              <Row>
                {cardsData.map((card, index) => (
                  <Col md={4} key={index} className="mb-2">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          Order Number: {card.orderNumber}
                        </Card.Title>
                        <Card.Text>
                          Phone Number: <strong>{card.phoneNumber}</strong>
                        </Card.Text>
                        <Card.Text className="text-capitalize">
                          Status: <strong>{card.status}</strong>
                        </Card.Text>
                        <Card.Text>
                          Store Code: <strong>{card.storeCode}</strong>
                        </Card.Text>
                        <Card.Text className="text-capitalize">
                          Delivery Type: <strong>{card.deliveryType}</strong>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              {selectedCard && (
                <Modal
                  show={show}
                  onHide={handleClose}
                  centered
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title>Online Order</Modal.Title>
                    <div>
                      <button
                        className="btn btn-sm btn-dark"
                        type="button"
                        onClick={redirectBack}
                      >
                        Back
                      </button>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Order ID: <strong>{selectedCard.orderNumber}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Customer Name:{" "}
                      <strong>{selectedCard.customerName}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Status: <strong>{selectedCard.status}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Phone Number: <strong>{selectedCard.phoneNumber}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Delivery Type:{" "}
                      <strong>{selectedCard.deliveryType}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Order From: <strong>{selectedCard.orderFrom}</strong>
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="mb-2 text-capitalize"
                    >
                      Amount: <strong>${selectedCard.grandTotal}</strong>
                    </p>
                  </Modal.Body>
                  <Modal.Footer style={{ borderTop: "none" }}>
                    <Button
                      className="btn btn-secondary"
                      onClick={handleSkipCard}
                    >
                      Skip
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#ff8c00",
                        color: "white",
                        border: "#ff8c00",
                      }}
                      onClick={() => {
                        handleAcceptCard(cardsData.indexOf(selectedCard));
                        navigate("/orders");
                      }}
                    >
                      Accept
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid row justify-content-center align-items-center">
            <div className="col-4 text-center">
              <button
                className="btn btn-sm btn-dark"
                type="button"
                onClick={redirectBack}
              >
                Back to Home
              </button>
            </div>
          </div>
        </>
      )}

      <ToastContainer position="top-center" />
    </>
  );
}

export default AudioCard;
