import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LoadingLayout from "./LoadingLayout";
import { useSelector } from "react-redux";
import bellSound from '../bell-sound.wav';
import { useSocket } from "../context/SocketContext";
import { getAcceptScreenOrder } from '../API/order';


function AuthLayout({ children }) {
    const [ok, setOk] = useState(false);
    const audioRef = useRef(null);
    const buttonRef = useRef(null);
    const [orders, setOrders] = useState([]);
    const [buttonText, setButtonText] = useState("Click Me");

    const socket = useSocket();

    const navigate = useNavigate();

    const userData = useSelector((state) => state.user.userData);
    const role = userData?.role ?? "R_3";
    const storeLocation = userData?.storeLocation ?? "STR_1";

    useEffect(() => {
        if (userData) {
            setOk(true);
        } else {
            setOk(false);
            navigate("/");
        }
    }, [userData, navigate]);

    // const loadingExistingOrder = async () => {
    //     const result = await getAcceptScreenOrder(storeLocation);
    //     if (result.status === 200) {
    //         let data = result.data.data;
    //         const placedOrders = data.filter(order => order.status === "pending");
    //         if (placedOrders.length > 0) setOrders(placedOrders);
    //     }
    // }
    // useEffect(() => {
    //     socket.on("onlineorder", (data) => {
    //         if (data?.storeCode === storeLocation) {
    //             setOrders((prevOrders) => [...prevOrders, data]);
    //             handlePlay();
    //         }
    //     });
    //     return () => {
    //         socket.off("onlineorder");
    //     };
    // }, [socket, storeLocation]);

    //   const handlePlay = () => {
    //     if (audioRef.current) {
    //       audioRef.current.play();
    //     }
    //   };

    // const handlePause = () => {
    //     if (audioRef.current) {
    //         audioRef.current.pause();
    //         audioRef.current.currentTime = 0;
    //     }
    // };

    // const handleClick = () => {
    //     setButtonText("Button Clicked");
    // };

    // useEffect(() => {
    //     loadingExistingOrder();
    // }, [orders, storeLocation])

    // useEffect(() => {
    //     if (buttonRef.current) {
    //         buttonRef.current.click();
    //     }

    //     if (orders.length > 0) {
    //         audioRef.current.loop = true;
    //         handlePlay();
    //     } else {
    //         handlePause();
    //     }
    // }, [orders, audioRef]);

    return (
        <div>
            <div>
                {/* <button ref={buttonRef} onClick={handleClick} className="d-none">
                    {buttonText}
                </button> */}
                {/* <audio ref={audioRef} src={bellSound} /> */}
            </div>
            {children}
        </div>
    );
}

export default AuthLayout;
